import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import "react-step-progress-bar/styles.css";
import SopotakContext from "../../../store/sopotak-context";
import OrderListPanel from "./OrderListPanel";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../../../styles.css";

const OrderPane = (props) => {
  const {
    tripType,
    departureOffer,
    returnOffer,
    setDepartureOffer,
    setReturnOffer,
    offersData,
  } = useContext(SopotakContext);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedDepartureEndTime, setSelectedDepartureEndTime] =
    useState(null);
  const [selectedReturnStartTime, setSelectedReturnStartTime] = useState(null);

  const departureListRef = useRef(null);
  const nextDateRef = useRef(null);
  const noOffersMessageRef = useRef(null);

  useEffect(() => {
    console.log("offers state", departureOffer, returnOffer);
    if (tripType === "RETURN") {
      setIsButtonDisabled(!departureOffer || !returnOffer);
    } else {
      setIsButtonDisabled(!departureOffer);
    }
  }, [departureOffer, returnOffer, tripType]);

  useEffect(() => {
    setSelectedDepartureEndTime(null);
    setSelectedReturnStartTime(null);
    setDepartureOffer(null);
    setReturnOffer(null);

    if (offersData?.departureOffers?.length && departureListRef.current) {
      // Scroll to the list of offers if available
      departureListRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!offersData?.departureOffers?.length) {
      // Scroll to next available date if exists
      if (offersData?.nextAvailableDate && nextDateRef.current) {
        nextDateRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // Scroll to no offers message if no offers and no next available date
      else if (!offersData?.nextAvailableDate && noOffersMessageRef.current) {
        noOffersMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [offersData]);

  const submitHandler = (event) => {
    event.preventDefault();
    props.progressHandler(2);
    console.log("context after selection", {
      tripType,
      departureOffer,
      returnOffer,
    });
  };

  const handleDepartureOffer = useCallback(
    (offer) => {
      setDepartureOffer(offer);
      if (offer) {
        setSelectedDepartureEndTime(new Date(offer.arrivalTime).getTime());
      } else {
        setSelectedDepartureEndTime(null);
      }
    },
    [setDepartureOffer]
  );

  const handleReturnOffer = useCallback(
    (offer) => {
      setReturnOffer(offer);
      if (offer) {
        setSelectedReturnStartTime(new Date(offer.departureTime).getTime());
      } else {
        setSelectedReturnStartTime(null);
      }
    },
    [setReturnOffer]
  );

  const renderOffers = () => {
    const departureOffersExist = offersData?.departureOffers?.length > 0;
    const returnOffersExist = offersData?.returnOffers?.length > 0;
    const nextAvailableDate = offersData?.nextAvailableDate;

    if (!offersData) {
      return null;
    }

    // Case: No departure offers, check nextAvailableDate and show appropriate message
    if (!departureOffersExist) {
      if (nextAvailableDate !== null && nextAvailableDate !== undefined) {
        // Check if nextAvailableDate is a valid date
        const validDate = new Date(nextAvailableDate);
        if (!isNaN(validDate.getTime())) {
          // Case: No offers but a valid next available date exists
          return (
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col xs="auto">
                  <h5 ref={nextDateRef}>
                    Nie znaleziono ofert. Najbliższy możliwy termin dla Twojego
                    kursu to {validDate.toLocaleDateString("pl-PL")}.
                  </h5>
                </Col>
              </Row>
            </Container>
          );
        }
      }

      // Case: No offers and no valid next available date (nextAvailableDate is null or undefined)
      return (
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="auto">
              <h5 ref={noOffersMessageRef}>
                Nie znaleziono ofert. Zmień parametry wyszukiwania.
              </h5>
            </Col>
          </Row>
        </Container>
      );
    }

    // Case: Departure offers exist, but return offers are empty
    return (
      <div className="form-wrapper">
        <Container fluid>
          <Row style={{ marginBottom: "30px" }}>
            <Col className="col-12 gx-2">
              <OrderListPanel
                ref={departureListRef}
                orderList={offersData.departureOffers}
                direction="Tam"
                date={offersData.departureDate}
                offerHandler={handleDepartureOffer}
                selectedReturnStartTime={selectedReturnStartTime}
              />
            </Col>
          </Row>


          {tripType === "RETURN" && (
            <Row className="mb-2">
              <Col className="col-12 gx-2">
                <OrderListPanel
                  orderList={offersData.returnOffers}
                  direction="Z powrotem"
                  date={offersData.arrivalDate}
                  offerHandler={handleReturnOffer}
                  selectedDepartureEndTime={selectedDepartureEndTime}
                  nextAvailableDate={offersData.nextAvailableDate}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col className="col-12 gx-2">
              <Button
                size="lg"
                onClick={submitHandler}
                className="w-100"
                disabled={isButtonDisabled}
              >
                Rezerwuj
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <div>
      <section>{renderOffers()}</section>
    </div>
  );
};

export default OrderPane;
